import CoursesService from '@/services/courses'
import { generateCourseLabel } from '@/utils/dataLabels'
import { courseNameWithFallbacks } from '@/utils/entityNames'

const attributeName = 'data-course-id'
const courses = {}

const __deprecatedGetCourse = ({ school, courseId, $axios }) =>
  $axios.$get(`/cm/${school}/courses/${courseId}`).catch((error) => {
    // per https://www.notion.so/coursedog1/ALL-Handle-404-errors-346109fdc4a14f44b7b010c2a8de5860
    // if the course is not found via both the normal and deprecated getCourse functions, it's not present in the catalog
    // we don't want to pollute bugsnag with those errors
    if (error.response.status !== 404) {
      throw error
    }
  })

export default {
  selector: `a[${attributeName}]`,
  processor: async (element, config) => {
    const enableFetchingRequisitesWithLatestRevisions =
      config.$store.state.settings.settings
        .enableFetchingRequisitesWithLatestRevisions
    const courseId = element.getAttribute(attributeName)
    if (!courses[courseId]) {
      if (enableFetchingRequisitesWithLatestRevisions) {
        courses[courseId] = null
      } else {
        const course = await __deprecatedGetCourse({
          courseId,
          school: config.school,
          $axios: config.$axios,
        })
        if (course) {
          courses[courseId] = course
          element.href = course.courseGroupId
        }
      }
    }
    const course = courses[courseId]
    if (course) {
      element.textContent = course.code || courseNameWithFallbacks(course)
      element.addEventListener('click', (event) => {
        event.preventDefault()
        config.vm.showDetails({
          path: 'courses',
          course,
          label: generateCourseLabel(course),
          description: course.description,
          id: course.courseGroupId,
        })
      })
    } else if (enableFetchingRequisitesWithLatestRevisions) {
      element.className = 'visually-hidden'
    }
  },
  preFetch: async (nodesList, config) => {
    const courseIds = []
    nodesList.forEach((element) =>
      courseIds.push(element.getAttribute(attributeName))
    )
    if (!courseIds.length) return
    const api = new CoursesService(config.$axios, config.$store)
    const fetchedCourse = await api.getCourses({
      courseGroupIds: courseIds,
      formatDependents: false,
    })
    if (fetchedCourse.data?.length) {
      fetchedCourse.data.forEach((course) => {
        courses[course.courseGroupId] = course
      })
    }
  },
}
