import courseLink from './courseLinkExtender'
import programLink from './programLinkExtender'
import pageLink from './pageLinkExtender'

const extenders = [courseLink, programLink, pageLink]

export default async function extendContent(wrapperElement, config) {
  for (const extender of extenders) {
    if (!wrapperElement) return
    const nodesList = wrapperElement.querySelectorAll(extender.selector)
    if (extender.preFetch) {
      await extender.preFetch(nodesList, config)
    }
    nodesList.forEach((element) =>
      extender.processor(element, config, nodesList)
    )
  }
}
