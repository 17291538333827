import { assembleUrl } from '@/utils/url'

export default class ProgramsService {
  constructor($axios, $store) {
    this.axios = $axios
    this.school = $store.state.settings.school
    this.activeCatalog = $store.state.settings.activeCatalog
    this.effectiveDatesRange =
      $store.getters['settings/effectiveDatesRangeAsString']
    this.programsUrl = `/cm/${this.school}/programs`

    this.programsFilters = $store.state.settings.programsFilters
    this.hasFilters =
      this.programsFilters?.filters && this.programsFilters?.filters.length > 0

    this.filters = this.hasFilters
      ? {
          ...this.programsFilters,
          filters: this.programsFilters.filters,
        }
      : {}
  }

  getPrograms({
    query = '$filters',
    skip = 0,
    limit = 50000,
    filters = this.filters,
    columns = [],
    department,
    sortBy,
    programGroupIds,
  }) {
    const skipParam = encodeURIComponent(skip)
    const queryParam = encodeURIComponent(query)
    const limitParam = encodeURIComponent(limit)
    let url = assembleUrl(`${this.programsUrl}/search/${queryParam}`, {
      catalogId: this.activeCatalog,
      skip: skipParam,
      limit: limitParam,
      sortBy,
      effectiveDatesRange: this.effectiveDatesRange,
      ...(columns.length ? { columns: columns.join(',') } : {}),
    })
    if (department) url += `&departments=${department}`
    return this.axios.$post(url, {
      ...filters,
      ...(programGroupIds ? { programGroupIds } : {}),
    })
  }

  getProgram(programGroupId, {includeMappedDocumentItems = false} = {}) {
    const url = assembleUrl(this.programsUrl, {
      programGroupIds: programGroupId,
      effectiveDatesRange: this.effectiveDatesRange,
      doNotDisplayAllMappedRevisionsAsDependencies: true,
      formatDependents: true,
      includeMappedDocumentItems,
    })
    return this.axios.$get(url).then((data) => Object.values(data)[0])
  }
}
