<template>
  <section>
    <article
      ref="content"
      class="main-content"
      data-test="rich-content"
      v-html="pageContent"
    />
    <ItemDetailsModal
      :details="details"
      @close="details = false"
    >
      <template v-if="details.course">
        <ResourcePreview
          v-if="configurableResourcePreview"
          class="rounded bg-white border border-gray-300 my-4"
          :value="details.course"
          :template="coursePreviewTemplate"
        />
        <CourseModalCreditsDetails
          v-else-if="showCreditsOnCourseModal"
          :course="details.course"
          class="mt-4"
        />
      </template>
    </ItemDetailsModal>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ItemDetailsModal from './ItemDetailsModal'
import CourseModalCreditsDetails from '@/components/CourseModalCreditsDetails'
import contentExtenderMixin from '@/mixins/contentExtenderMixin'
import ResourcePreview from '@/components/ResourcePreview'
import useFeatureFlags from '@/composables/useFeatureFlags'
import { CONFIGURABLE_RESOURCE_PREVIEW } from '@/constants/featureFlags'

export default {
  setup() {
    const { configurableResourcePreview } = useFeatureFlags({
      configurableResourcePreview: CONFIGURABLE_RESOURCE_PREVIEW,
    })
    return { configurableResourcePreview }
  },
  components: { ItemDetailsModal, CourseModalCreditsDetails, ResourcePreview },
  mixins: [contentExtenderMixin],
  props: {
    content: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapState('settings', ['coursePreviewTemplate']),
    ...mapGetters('settings', ['showCreditsOnCourseModal']),
    pageContent() {
      // for tables with a fixed columns width, we need to change attribute data-colwidth to width
      return this.content?.replace(/data-colwidth/, 'width') || ''
    },
  },
  watch: {
    content() {
      this.enrichContent()
    },
  },
}
</script>

<style lang="scss" scoped>
/* For any Wysiwyg content styling inconsistency add the missing styles here */
/* This should be kept in sync with cousedogv3/src/css/editor_content.scss to ensure consistency */

.main-content {
  --block-spacing: 15px;
  --heading-margin-before: 1em;
  --heading-margin-after: 0em;
  --heading-1-size: 1.3rem;
  --heading-2-size: 1.25rem;
  --heading-3-size: 1.1rem;
  --heading-4-size: 1rem;

  &::v-deep {
    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    ol,
    ul {
      li {
        margin-bottom: 0.2em;
        line-height: 1.3em;
      }
    }

    p,
    ul,
    ol,
    pre {
      margin-bottom: var(--block-spacing);
    }

    h1,
    h2,
    h3,
    h4 {
      margin-top: var(--heading-margin-before);
      margin-bottom: var(--heading-margin-after);
    }

    h1 {
      font-size: var(--heading-1-size);
      font-weight: bold;
    }

    h2 {
      font-size: var(--heading-2-size);
      font-weight: bold;
    }

    h3 {
      font-size: var(--heading-3-size);
      font-weight: bold;
    }

    h4 {
      font-size: var(--heading-4-size);
      font-weight: bold;
    }

    blockquote {
      padding-left: 0.5em;
      border-left: 3px solid currentColor;

      p:last-child {
        margin-bottom: 0;
      }
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 0.5em;

      background: #000000;

      color: #ffffff;
      font-size: 0.8rem;

      overflow-x: auto;

      code {
        display: block;
      }
    }

    a {
      @apply text-theme-400;

      &:hover {
        @apply underline;
      }
    }

    img {
      max-width: 100%;
    }

    figure.rich-image {
      text-align: center;

      &.float-right {
        margin-left: 1em;
        margin-bottom: 0.5em;
      }

      &.float-left {
        margin-right: 1em;
        margin-bottom: 0.2em;
      }

      img {
        margin: auto;
      }
    }
    td > p,
    th > p {
      margin-bottom: 0;
    }
  }
}
</style>
