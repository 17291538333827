const pages = {}

function findPageInNavigation(navigationLinks, pageId) {
  return navigationLinks.reduce((a, item) => {
    if (a) return a
    if (item.pageId === pageId) return item
    if (item.children) return findPageInNavigation(item.children, pageId)
  }, null)
}

export default {
  selector: 'a[data-page-id]',
  processor: async (element, config) => {
    const pageId = element.getAttribute('data-page-id')
    const subPageId = element.getAttribute('data-sub-page-id')
    if (!pages[pageId]) {
      const page = await config.$axios.$get(
        `/ca/${config.$store.state.settings.school}/catalogPages/${
          config.$store.state.settings.activeCatalog
        }/${pageId}`
      )
      pages[pageId] = page
    }

    const allNavLinks = [
      ...config.$store.state.settings.navbar,
      ...config.$store.state.settings.sidebar,
    ]

    const page = pages[pageId]
    let href = ''

    switch (page.type) {
      // by default we send user to the overview tab of the department page
      case 'department': {
        href = `/departments/${encodeURIComponent(
          page.objectId || page.id.split('-').pop()
        )}/overview`
        break
      }
      case 'departments': {
        href = `/departments`
        break
      }
      default: {
        const navLink = findPageInNavigation(allNavLinks, page.id)
        if (navLink && navLink.url) {
          href = navLink.url
        } else {
          href = `/pages/${page.slug || page.id}`
        }
        break
      }
    }

    if (subPageId) {
      const tabs = (page.widgets || []).find((w) => w.type === 'tabs') || {
        data: [],
      }
      const subPage = tabs.data.find((sp) => sp.id === subPageId)
      href = `${href}#${subPage.anchor}`
    }
    element.setAttribute('href', href)
  },
}
