import ProgramsService from '@/services/programs'
import { generateProgramLabel } from '@/utils/dataLabels'
import { programNameWithFallbacks } from '@/utils/entityNames'

const attributeName = 'data-program-id'
const programs = {}

const __deprecatedGetProgram = ({ school, programId, $axios }) =>
  $axios.$get(`/cm/${school}/programs/${programId}`).catch((error) => {
    // per https://www.notion.so/coursedog1/ALL-Handle-404-errors-346109fdc4a14f44b7b010c2a8de5860
    // if the program is not found via both the normal and deprecated getProgram functions, it's not present in the catalog
    // we don't want to pollute bugsnag with those errors
    if (error.response.status !== 404) {
      throw error
    }
  })

export default {
  selector: `a[${attributeName}]`,
  processor: async (element, config) => {
    const enableFetchingRequisitesWithLatestRevisions =
      config.$store.state.settings.settings
        .enableFetchingRequisitesWithLatestRevisions
    const programId = element.getAttribute(attributeName)
    if (!programs[programId]) {
      if (enableFetchingRequisitesWithLatestRevisions) {
        programs[programId] = null
      } else {
        const program = await __deprecatedGetProgram({
          programId,
          school: config.school,
          $axios: config.$axios,
        })
        if (program) {
          programs[programId] = program
          element.href = program.programGroupId
        }
      }
    }
    const program = programs[programId]
    if (program) {
      element.textContent =
        programNameWithFallbacks(program) || program.code || program.id
      element.addEventListener('click', (event) => {
        event.preventDefault()
        config.vm.showDetails({
          path: 'programs',
          label: generateProgramLabel(program, config.school),
          description: program.description,
          id: program.programGroupId,
        })
      })
    } else if (enableFetchingRequisitesWithLatestRevisions) {
      element.className = 'visually-hidden'
    }
  },
  preFetch: async (nodesList, config) => {
    const programIds = []
    nodesList.forEach((element) =>
      programIds.push(element.getAttribute(attributeName))
    )
    if (!programIds.length) return
    const api = new ProgramsService(config.$axios, config.$store)
    const fetchedPrograms = await api.getPrograms({
      programGroupIds: programIds,
    })
    if (fetchedPrograms.data?.length) {
      fetchedPrograms.data.forEach((program) => {
        programs[program.programGroupId] = program
      })
    }
  },
}
