import { mapState, mapGetters } from 'vuex'
import extendContent from '@/utils/contentExtender'

export default {
  data() {
    return {
      details: false,
    }
  },
  computed: {
    ...mapState('settings', ['school']),
    ...mapGetters('settings', ['hideCourseModal', 'hideProgramModal']),
  },
  mounted() {
    this.enrichContent(true)
  },
  methods: {
    showDetails(details) {
      if (this.hideCourseModal && details.path === 'courses') {
        return this.$router.push(`/${details.path}/${encodeURIComponent(details.id)}`)
      }
      if (this.hideProgramModal && details.path === 'programs') {
        return this.$router.push(`/${details.path}/${details.id}`)
      }
      this.details = details
    },
    closeDetails() {
      this.details = false
    },
    enrichContent(retry = false) {
      this.$nextTick(() => {
        if (!this.$refs.content) {
          console.warn('Content ref not initialized')
          if (retry) setTimeout(this.enrichContent, 1000)
          return
        }

        extendContent(this.$refs.content, {
          school: this.school,
          $axios: this.$axios,
          $store: this.$store,
          vm: this,
        })
      })
    },
  },
}
